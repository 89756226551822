.buzz_page_wrapper {
    .body_wrapper {
        padding: 32px 36px;
        column-gap: 1.5vw;
        row-gap: 1.5vw;
    }
}

@media screen and (max-width: 800px) {
    .buzz_page_wrapper {
        .body_wrapper {
            padding: 32px 24px;
            row-gap: 24px;
            margin-bottom: 120px;
        }
    }
}