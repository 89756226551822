.overlay_card_wrapper {
    height: 100%;
    
    .over_lay_card {
        height: 100%;
        border-radius: 20px;
        position: relative;
        
        .over_text_wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 3;
            display: flex;
            padding: 20px;
            align-items: flex-end;
            justify-content: flex-start;
            .text_wrapper {
                max-width: 154px;
            }
        }
        
        .gradient_wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 2;
            border-radius: 20px;
            width: 100%;
            background: linear-gradient(192deg, rgba(3, 10, 33, 0.00) 59.32%, #030A21 82.49%);
        }
        
        .image_wrapper {
            z-index: 1;
            height: 100%;
            img {
                border-radius: 20px;
                height: 100%;
                width: 100%;
                border: 1px solid #030a21;
            }
        }
    }
}