.page_header {
    padding: 60px 36px 0px 36px;
    .icon_wrapper {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .page_header {
        display: flex;
        padding: 16px 24px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid var(--gs-learn-light-mode-grey-4, rgba(3, 10, 33, 0.10));
        .icon_wrapper {
            display: block;
        }
    }
}